<template>
	<v-card class="ma-4 pa-4">
		<v-row>
			<v-col cols="3" class="pa-0">
				<h2>GMB</h2>
			</v-col>
			<v-spacer></v-spacer>
			<v-col cols="2" class="pa-0 mr-2">
				<v-menu
					ref="menu"
					v-model="menu"
					:close-on-content-click="false"
					:return-value.sync="date"
					transition="scale-transition"
					offset-y
					max-width="290px"
					min-width="auto"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							v-model="date"
							label="Month"
							prepend-icon="mdi-calendar"
							readonly
							dense
							outlined
							v-bind="attrs"
							v-on="on"
						></v-text-field>
					</template>
					<v-date-picker
						v-model="date"
						type="month"
						min="2021-08"
						:max="maxDate"
						no-title
						scrollable
					>
						<v-spacer></v-spacer>
						<v-btn text color="primary" @click="menu = false">
							Cancel
						</v-btn>
						<v-btn
							text
							color="primary"
							@click="$refs.menu.save(date)"
						>
							OK
						</v-btn>
					</v-date-picker>
				</v-menu>
			</v-col>
			<v-col cols="3" class="pa-0 ma-0">
				<v-select
					:items="locations"
					v-model="selectedLocations"
					label="Select Location"
					outlined
					multiple
					dense
				>
					<template v-slot:selection="{ index }">
						<span
							class="grey--text text-caption"
							v-if="index == selectedLocations.length - 1"
						>
							{{ selectedLocations.length }} location(s) selected
						</span>
					</template>
					<template v-slot:prepend-item>
						<v-list-item ripple @click="toggle">
							<v-list-item-action>
								<v-icon
									:color="
										selectedLocations.length > 0
											? 'indigo darken-4'
											: ''
									"
								>
									{{ icon }}
								</v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title>
									Select All
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="mt-2"></v-divider>
					</template>
				</v-select>
			</v-col>
			<v-col cols="1" style="padding-top: 0px; margin-right: 20px">
				<v-btn @click="setSelected" tile> Search</v-btn>
			</v-col>
			<v-col cols="12" v-if="selectedLocations">
				<v-card v-if="fetchingData" class="pa-4" outlined width="100%">
					<v-skeleton-loader type="card"></v-skeleton-loader>
				</v-card>
				<v-expansion-panels>
					<!-- sometimes different accounts handle same locations and this will create 
						duplicated key error to overcome this we use key=>"id+index" -->
					<v-expansion-panel
						v-for="(expected, index) in expectedData"
						:key="expected._id + index"
					>
						<div v-if="expected.expectations">
							<v-expansion-panel-header>
								<v-row no-gutters>
									<v-col
										cols="12"
										class="pr-1"
										style="text-align: start"
									>
										<h4>{{ expected.name }}</h4>
									</v-col>
									<v-col
										cols="12"
										class="d-flex flex-row mt-3"
									>
										<v-col
											cols="2"
											class="pl-0 pr-1 d-flex flex-row"
											style="text-align: start"
											>Posts:
											<p
												class="pl-1"
												:style="{
													color:
														expected.overallActualPosts -
															expected.overallExpectedPosts >=
														0
															? 'green'
															: 'red',
												}"
											>
												{{
													expected.overallActualPosts
												}}/{{
													expected.overallExpectedPosts
												}}
											</p>
										</v-col>
										<v-col
											cols="2"
											class="pr-1 d-flex flex-row"
											style="text-align: start"
											>Q&A:
											<p
												class="pl-1"
												:style="{
													color:
														expected.overallActualQA -
															expected.overallExpectedQA >=
														0
															? 'green'
															: 'red',
												}"
											>
												{{
													expected.overallActualQA
												}}/{{
													expected.overallExpectedQA
												}}
											</p>
										</v-col>
										<v-col
											cols="3"
											class="pr-1 d-flex flex-row"
											style="text-align: start"
											>Total Reviews:
											<p
												class="pl-1"
												:style="{
													color:
														expected.overallActualTotalReviews -
															expected.overallExpectedTotalReviews >=
														0
															? 'green'
															: 'red',
												}"
											>
												{{
													expected.overallActualTotalReviews
												}}/{{
													expected.overallExpectedTotalReviews
												}}
											</p>
										</v-col>
										<v-col
											cols="3"
											class="pr-1 d-flex flex-row"
											style="text-align: start"
											>Replied Reviews:
											<p
												class="pl-1"
												:style="{
													color:
														expected.overallActualRepliedReviews -
															expected.overallExpectedRepliedReviews >=
														0
															? 'green'
															: 'red',
												}"
											>
												{{
													expected.overallActualRepliedReviews
												}}/{{
													expected.overallExpectedRepliedReviews
												}}
											</p>
										</v-col>
										<v-col
											cols="2"
											style="text-align: start"
										>
											<div
												class="
													pa-0
													pr-1
													d-flex
													flex-row
												"
											>
												<p>Photos:</p>
												<p
													class="pl-1"
													:style="{
														color:
															expected.overallActualPhotos -
																expected.overallExpectedPhotos >=
															0
																? 'green'
																: 'red',
													}"
												>
													{{
														expected.overallActualPhotos
													}}/{{
														expected.overallExpectedPhotos
													}}
												</p>
											</div>

											<h6>
												*values till previous week(7
												days ago)
											</h6>
										</v-col>
									</v-col>
								</v-row>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-card
									class="pa-2"
									v-for="(
										expectedObj, index
									) in expected.expectations"
									:key="index"
								>
									<div
										class="
											d-flex
											justify-start
											align-center
										"
									>
										<p
											class="
												date-box
												deep-purple--text
												text--darken-2
												py-1
												px-2
											"
										>
											Date Range:
											{{
												new Date(
													expectedObj.dateStarted
												).getDate()
											}}
											-
											{{
												new Date(
													expectedObj.dateEnded
												).getDate()
											}}
										</p>
									</div>
									<v-simple-table>
										<template v-slot:default>
											<thead>
												<tr class="ma-0 pa-0 red--text">
													<th class="title">Name</th>
													<th
														class="
															text-center
															title
														"
														style="
															padding-left: 34px;
														"
													>
														Expected
													</th>
													<th
														class="
															text-center
															title
														"
													>
														Actual
													</th>
													<th
														class="
															text-center
															title
														"
													>
														Difference
													</th>
												</tr>
											</thead>
											<tbody>
												<tr
													v-for="metric in expectedObj.metricValues"
													:key="metric.metric"
												>
													<td class="subtitle-2">
														{{ metric.metric }}
													</td>
													<td>
														<v-text-field
															class="
																shrink
																text-field
																text-center
															"
															dense
															solo
															flat
															v-model="
																metric.expectedValue
															"
															hide-details
														></v-text-field>
													</td>
													<td class="text-center">
														{{ metric.actualValue }}
													</td>
													<td
														class="text-center"
														:style="{
															color:
																metric.actualValue -
																	metric.expectedValue >=
																0
																	? 'green'
																	: 'red',
														}"
													>
														{{
															metric.actualValue -
															metric.expectedValue
														}}
													</td>
												</tr>
											</tbody>
										</template>
									</v-simple-table>
								</v-card>
								<v-btn
									v-if="isUpdateAllowed"
									style="float: right"
									@click="updateValue(index)"
									color="primary"
									>Save</v-btn
								>
							</v-expansion-panel-content>
						</div>
						<div v-else class="mx-6 my-7">
							<v-row no-gutters>
								<v-col
									cols="12"
									class="pr-1"
									style="text-align: start"
								>
									<h4>{{ expected.name }}</h4>
								</v-col>
								<v-col>
									<p class="mt-4">
										Location's data not available for this
										month (Try selecting next month).
									</p>
								</v-col>
							</v-row>
						</div>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import moment from 'moment'
export default {
	data() {
		return {
			date: moment().format('YYYY-MM'),
			maxDate: moment().add(2, 'M').format('YYYY-MM-DD'),
			menu: false,
			fetchingData: false,
			expectedData: [],
			expectValue: null,
			selectedLocations: [],
			selectedLocationNames: [],
			locations: [],
		}
	},
	created() {
		this.getGmbLocations()
	},
	computed: {
		isUpdateAllowed() {
			// only allow for current and future months.
			return moment().format('YYYY-MM') <= this.date
		},
		likesAllLocations() {
			return this.selectedLocations.length === this.locations.length
		},
		likesSomeLocations() {
			return this.selectedLocations.length > 0 && !this.likesAllLocations
		},
		icon() {
			if (this.likesAllLocations) return 'mdi-close-box'
			if (this.likesSomeLocations) return 'mdi-minus-box'
			return 'mdi-checkbox-blank-outline'
		},
	},
	methods: {
		toggle() {
			this.$nextTick(() => {
				if (this.likesAllLocations) {
					this.selectedLocations = []
					this.selectedLocationNames = []
				} else {
					this.selectedLocations = this.locations.slice()
				}
			})
		},
		getGmbLocations() {
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'get',
				url: `/${projectId}/gmb/locations`,
			})
				.then((res) => {
					this.$nextTick(() => {
						this.locationsArr = res.data.data
						res.data.data.map((location) => {
							let locationName = `${location.locationName} (${location.address})`
							this.locations = [...this.locations, locationName]
						})
					})
				})
				.catch((err) => {
					this.$snackbar.notify({
						message: err.response.data.message,
						color: 'error',
					})
				})
		},
		setSelected() {
			this.selectedLocations.forEach((selectedLocation, index) => {
				this.locationsArr.forEach((location) => {
					if (
						`${location.locationName} (${location.address})` ===
						selectedLocation
					) {
						this.selectedLocationNames[index] = location.name
					}
				})
			})
			this.getExpectedValues()
		},
		getExpectedValues() {
			if (this.selectedLocationNames.length > 0) {
				this.expectedData = []
				this.fetchingData = true
				const projectId = this.$store.state.app.projectId
				this.axios({
					method: 'post',
					url: `/${projectId}/gmb/locations/expected`,
					params: {
						month: this.date,
					},
					data: {
						locations: this.selectedLocationNames,
					},
				})
					.then((res) => {
						if (res.status === 200) {
							this.expectedData = res.data.data
							this.expectedData.forEach((obj, index) => {
								this.locationsArr.forEach((location) => {
									if (location.name === obj.name) {
										obj.name = `${location.locationName} (${location.address})`
									}
								})
								//Actual Values
								obj.overallActualPosts = 0
								obj.overallActualQA = 0
								obj.overallActualTotalReviews = 0
								obj.overallActualRepliedReviews = 0
								obj.overallActualPhotos = 0

								//Expected Values
								obj.overallExpectedPosts = 0
								obj.overallExpectedQA = 0
								obj.overallExpectedTotalReviews = 0
								obj.overallExpectedRepliedReviews = 0
								obj.overallExpectedPhotos = 0
								if (obj.expectations.length > 0) {
									const currentDate =
										moment().format('YYYY-MM-DD')
									let lastWeekIndex = null
									obj.expectations.forEach(
										(expectedObj, index) => {
											if (
												currentDate >=
													moment(
														expectedObj.dateStarted
													).format('YYYY-MM-DD') &&
												currentDate <=
													moment(
														expectedObj.dateEnded
													).format('YYYY-MM-DD')
											) {
												lastWeekIndex = index - 1
											}
										}
									)
									obj.expectations.forEach(
										(expectedObj, index) => {
											expectedObj.metricValues.forEach(
												(metric) => {
													if (
														metric.metric ===
														'POSTS'
													) {
														obj.overallActualPosts +=
															metric.actualValue
														obj.overallExpectedPosts +=
															metric.expectedValue
													} else if (
														metric.metric === 'Q&A'
													) {
														obj.overallActualQA +=
															metric.actualValue
														obj.overallExpectedQA +=
															metric.expectedValue
													} else if (
														metric.metric ===
														'TOTAL_REVIEWS'
													) {
														obj.overallActualTotalReviews +=
															metric.actualValue
														obj.overallExpectedTotalReviews +=
															metric.expectedValue
													} else if (
														metric.metric ===
														'REPLIED_REVIEWS'
													) {
														obj.overallActualRepliedReviews +=
															metric.actualValue
														obj.overallExpectedRepliedReviews +=
															metric.expectedValue
													} else if (
														metric.metric ===
															'PHOTOS' &&
														lastWeekIndex === index
													) {
														/**
														 * we will not do this for the photos
														 * we will show only the current week value in
														 * overallexpected and actual value
														 */
														obj.overallActualPhotos =
															metric.actualValue
														obj.overallExpectedPhotos =
															metric.expectedValue
													}
												}
											)
										}
									)
									this.expectedData[index] = obj
								} else {
									obj.expectations = null
									this.expectedData[index] = obj
								}
							})
						}

						this.fetchingData = false
					})
					.catch((err) => {
						this.$snackbar.notify({
							message: err.response.data.message,
							color: 'error',
						})
						this.fetchingData = false
					})
			}
		},
		updateValue(selectedIndex) {
			const projectId = this.$store.state.app.projectId
			const locationData = this.expectedData[selectedIndex]
			locationData.expectations.forEach((expectedObj) => {
				expectedObj.updatedAt = new Date()
				expectedObj.metricValues.forEach((obj) => {
					if (obj.metric === 'PHOTOS') {
						obj.expectedValue =
							obj.actualValue + obj.expectedValue
								? parseInt(obj.actualValue, 10) +
								  parseInt(obj.expectedValue, 10)
								: 0
					}
					obj.expectedValue = obj.expectedValue
						? parseInt(obj.expectedValue, 10)
						: 0
				})
			})
			this.axios({
				method: 'put',
				url: `/${projectId}/gmb/locations/expected`,
				data: {
					...locationData,
				},
			})
				.then((res) => {
					if (res.status == 200 && res.data.success) {
						let data = res.data.data[0]
						this.locationsArr.forEach((location) => {
							if (location.name === data.name) {
								data.name = `${location.locationName} (${location.address})`
							}
						})
						this.$snackbar.notify({
							message: 'Updated Successfully.',
						})
						data = {
							...data,
							//Actual values
							overallActualPosts: 0,
							overallActualQA: 0,
							overallActualTotalReviews: 0,
							overallActualRepliedReviews: 0,
							overallActualPhotos: 0,
							//Expected Values
							overallExpectedPosts: 0,
							overallExpectedQA: 0,
							overallExpectedTotalReviews: 0,
							overallExpectedRepliedReviews: 0,
							overallExpectedPhotos: 0,
						}
						const currentDate = moment().format('YYYY-MM-DD')
						let lastWeekIndex = null
						data.expectations.forEach((expectedObj, index) => {
							if (
								currentDate >=
									moment(expectedObj.dateStarted).format(
										'YYYY-MM-DD'
									) &&
								currentDate <=
									moment(expectedObj.dateEnded).format(
										'YYYY-MM-DD'
									)
							) {
								lastWeekIndex = index - 1
							}
						})
						data.expectations.forEach((expectedObj, index) => {
							expectedObj.metricValues.forEach((metric) => {
								if (metric.metric === 'POSTS') {
									data.overallActualPosts +=
										metric.actualValue
									data.overallExpectedPosts +=
										metric.expectedValue
								} else if (metric.metric === 'Q&A') {
									data.overallActualQA += metric.actualValue
									data.overallExpectedQA +=
										metric.expectedValue
								} else if (metric.metric === 'TOTAL_REVIEWS') {
									data.overallActualTotalReviews +=
										metric.actualValue
									data.overallExpectedTotalReviews +=
										metric.expectedValue
								} else if (
									metric.metric === 'REPLIED_REVIEWS'
								) {
									data.overallActualRepliedReviews +=
										metric.actualValue
									data.overallExpectedRepliedReviews +=
										metric.expectedValue
								} else if (
									metric.metric === 'PHOTOS' &&
									lastWeekIndex === index
								) {
									/**
									 * we will not do this for the photos
									 * we will show only the current week value in
									 * overallexpected and actual value
									 */
									data.overallActualPhotos =
										metric.actualValue
									data.overallExpectedPhotos =
										metric.expectedValue
								}
							})
						})
						/* Because *array[index] = data* is not reactive in Vue and
						will not update data in template, thus using 'splice' method to overcome this.*/
						this.expectedData.splice(selectedIndex, 1, data)
						this.selectedLocationNames = []
					}
				})
				.catch((err) => {
					this.$snackbar.notify({
						message: err.response.data.message,
						color: 'error',
					})
				})
		},
	},
}
</script>

<style scoped>
.text-field {
	width: 80px;
	border-bottom: 1px solid;
	border-radius: 0;
	transform: translate(6.1rem, 0);
}
.date-box {
	border: 2px solid;
	border-radius: 5px;
}
</style>
